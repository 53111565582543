* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Gilam-Heavy"; */
}
/* @font-face {
  font-family: "GilamBlack.ttf";
  src: local("GilamBlack"),
   url("./Assets/fonts/Gilam-Black.ttf") format("truetype");
  font-weight: bold; 
} */

/* colors pallete
yellow #f8bd00
orangy #ce7a23
Griseson azul #686e7f
rosita #fceeea 
negro #212322  */

.gilam-black {
  font-family: "Gilam-Black";
 }
 
 .gilam-black-italic {
  font-family: "Gilam-BlackItalic" !important;
 }
 
 .gilam-bold {
  font-family: "GilamBold";
 } 

 .gilam-bold-italic {
  font-family: "GilamBoldItalic";
 }
  
 .gilam-book {
  font-family: "GilamBook";
 }
  
 .gilam-book-italic {
  font-family: "GilamBookItalic";
 }
  
 .gilam-extra-light {
  font-family: "GilamExtraLight";
 }
  
 .gilam-extra-light-italic {
  font-family: "GilamExtraLightItalic";
 }
  
 .gilam-heavy {
  font-family: "GilamHeavy";
 }
  
 .gilam-heavy-italic {
  font-family: "GilamHeavyItalic";
 }
  
 .gilam-light {
  font-family: "GilamLight";
 }  

 .gilam-light-italic {
  font-family: "GilamLightItalic";
 }  

 .gilam-regular {
  font-family: "GilamRegular";
 }  

 .gilam-regular-italic {
  font-family: "GilamRegularItalic";
 }

 .gilam-semibold {
  font-family: "GilamSemiBold";
 }

 .gilam-semibold-italic {
  font-family: "GilamSemiBoldItalic";
 }

 .gilam-thin {
  font-family: "GilamThin";
 }

 .gilam-thin-italic {
  font-family: "GilamThinItalic";
 }
/* img {
  max-width: 100%;
  height: auto;
} */
.App {
  min-height: 100vh; 
  /* width: 85vw; */
  /* width: 90vw; */
  /* max-width: 1900px; */
  margin: 0rem auto;
  /* margin: 0rem auto; */
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #f6f6f6;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container{
  padding-top: 0px !important;
}

.nav
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-family: Helvetica;
  font-size: 16pt;
  justify-content: space-between;
}

.nav-bar-btn-desa {
  background-color: #fceeea  !important;
  color: black !important;
  opacity: 0.8;
  border-radius: 8 !important; 
}

.nav-bar-btn {
  background-color: #ce7a23 !important;
  color: black !important;
  opacity: 0.8;
  border-radius: 8 !important; 
}
.nav-bar-btn-mobile {
  background-color: #fceeea  !important;
  color: black !important;
  opacity: 0.8;
  border-radius: 8 !important; 
  height: 40px;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.navbar-cart-icon {
  font-size: 1.15rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}
.home-banner-image-container-new {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 100px;
  width: 50px !important;
  height: 50px !important;
}
.test-p{
  background-color: pink;
  height: 100%,
}
.home-image-container {
  /* position: absolute; */
  /* top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 100px; */
  width: 100px !important;
  height: 200px !important;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  /* line-height: 5rem; */
  max-width: 600px;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
}
.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #fe9e0d;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #e48f0f;
}
.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}
.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}
.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}
.footer-wrapper {
  margin: 6rem 0rem;
  display: flex;
  margin-top: 10rem;
}
.footer-logo-container {
  max-width: 110px;
}
.footer-icons {
  margin-top: 2.5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.work-section-wrapper {
  margin-top: 15rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-height: 80px;
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    /* max-width: 95vw; */
    padding-left: 5%;
    padding-right: 5%;
  }
}
.intro-page {
  /* width: 90vw; */
  padding-left: 5%;
  padding-right: 5%;
  /* padding-top: 5%; */
  height: 70vw;
  /* background-color: #e8e8e8 */ 
}
.last-page-logo {

  height: 120px;
  width: 80;
}
.intro-page-logo {
  height: 150px;
  width: 100;
}
.intro-page-center-text {
  font-family: 'GilamLight';
  font-size: 33pt;
  line-height: 1.1;
  line-break: normal;
  font-weight: 200;
}
.intro-page-center-text-mobile {
  font-family: 'GilamLight';
  font-size: 33pt;
  line-height: 1.1;
  line-break: normal;
  text-align: center;
  font-weight: 600;
}

.intro-page-center-sub-text {
  font-family: 'GilamBold';
  font-size: 20pt;
  line-height: 1;
  /* line-break: normal; */
}
.intro-page-center-sub-text-mobile {
  font-family: 'GilamBold';
  font-size: 20pt;
  line-height: 1;
  text-align: center;
  /* line-break: normal; */
}

.navbar-text {
  font-family: Helvetica;
  font-size: 16pt;
}
.nav-logo {
  height: 40px;
  /* width: 100; */
}

.intro-page-interior {
  /* padding-left: 5%;
  padding-right: 5%; */
  /* padding-top: 5%; */
  /* height: 40vw; */
  height: 800px;
  /* background-color: #e8e8e8; */
  align-items: center;
  margin-top: 10rem;
  text-align: center;
}
nav.sticky {
  position: sticky;
  top: 0;
  z-index: 80;
}
.intro-page-interior-mobile {
  /* padding-top: 5%; */
  height: 700px;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.basic-padding {

  padding-left: 5%;
  padding-right: 5%;
}
nav{
  transition: 0.3s;
}
.basic-padding-navbar {
  position: relative;
  z-index: 80;
  padding-left: 5%;
  padding-right: 5%;
  position: "fixed";
  background-color: #f8bd00;
  border-bottom: 1px solid;
  border-color: black;
  height: 65px;
  /* opacity: 0.8; */
}
.color-intro-page {
  /* background-color: #e8e8e8; */
  background-image: url('./Assets/fotos_finales/HOME/01.jpg');
  background-size: cover;
}
.color-intro-page-mobile {
  /* background-color: #e8e8e8; */
  background-image: url('./Assets/fotos_finales/HOME/01.jpg');
  /* background-size: cover; */
  /* background-position: center; */
  background-position: top 50% right 50%;
  /* background-size: 50%; */
}
.footer-intro-logo {
  background-color: #f8bd00;
  /* height: 10vw; */
  justify-content: space-between;
}
.footer-intro-logo-mobile { 
  background-color: #f8bd00;
  height: 400px;
  justify-content: space-between;
}
.text-footer-color {
  color: #212322;
}

.text-footer-size {
  font-family: 'Helvetica';
  font-size: 14pt
}

.color-know-brick-page {
  background-color: #212322;
}
.know-brick-page-interior {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  /* height: 55vw; */
  /* height: 1400px; */
  background-color: #212322;
  /* background-color: white; */
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.know-brick-page-interior-mobile {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  /* height: 55vw; */
  /* height: 2600px; */
  background-color: #212322;
  /* background-color: white; */
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.first-section-know-brick {
  margin-top: 25px;
  margin-bottom: 5%;
  align-items: flex-start;
}
.first-section-left-text {
  font-size: 35pt;
  font-family: 'GilamBook';
  line-height: 0.75;
  text-align: left;
  color: #f8bd00;
  /* margin-bottom: 5%; */
}
.first-section-right-text {
  font-size: 16pt;
  font-family: 'HelveticaLight';
  color: #f8bd00;
  text-align: left;
  /* margin-bottom: 5%; */
}
.first-section-right-text-header {
  font-size: 35pt;
  font-family: 'GilamBook';
  color: #f8bd00
}
.second-section-text-color {
  color: #f8bd00;
  font-family: 'HelveticaLight';
  font-size: 14px
}
.second-text-right-age {
  font-size: 33pt;
  color: #f8bd00;
  /* font-weight: 400; */
  font-family: 'GilamBook';
}
.second-text-main-name {
  font-size: 33pt;
  color: #f8bd00;
  font-family: 'GilamSemiBold';
  /* font-weight: 700; */
}
.second-text-sub-name {
  font-size: 16px;
  color: #f8bd00;
  font-family: 'HelveticaLight';
  /* font-weight: 700; */
}
.first-section-know-pic {
  margin-top: 30px;
  /* margin-left: 15px; */
  height: 80px;
  width: 30;
}
.metas-section-know-pic {
  /* margin-top: 30px; */
  color: #f8bd00;
  /* margin-left: 15px; */
  height: 80px;
  width: 30;
}
.logo-stars {
  height: 65%;
  width: 65%;
  margin-top: 80px;
}
.logo-stars-mobile {
  height: 65%;
  width: 65%;
}
.logo-second {
  height: 60%;
  width: 100%;
  /* margin-top: 20px; */
}
.first-picture-desarrollo-detail {
  
  object-fit: contain;
  margin-top: 30px;
  /* margin-left: 15px; */
  /* height: 500px; */
  width: 100%;
  /* object-fit:fill; */
}
.second-picture-desarrollo-detail {
  
  object-fit: contain;
  /* margin-top: 30px; */
  /* margin-left: 15px; */
  /* height: 500px; */
  width: 100%;

}
.second-picture-desarrollo-detail-mobile {
  
  object-fit: contain;
  margin-top: 30px;
  /* margin-left: 15px; */
  /* height: 250px; */
  width: 100%;

}
.first-picture-desarrollo-detail-mobile {
  
  /* margin-top: 30px; */
  /* margin-left: 15px; */
  /* height: 250px; */
  object-fit: contain;
  width: 100%;

}
.first-picture-desarrollo-detail-exterior {
  
  /* margin-top: 30px; */
  /* margin-left: 15px; */
  /* height: 250px; */
  object-fit:scale-down;
  width: 100%;

}
.second-picture-desarrollo-detail-a{
  
  object-fit: contain;
  margin-top: 30px;
  /* margin-left: 15px; */
  /* height: 470px; */
  width: 100%; 

}
.roof-top-square{
  
  object-fit: contain;
  margin-top: 30px;
  /* margin-left: 15px; */
  /* height: 470px;
  width: 100%;  */

}
.second-picture-desarrollo-detail-mobil-a{
  
  object-fit: contain;
  margin-top: 30px;
  /* margin-left: 15px; */
  height: 470px;
  width: 100%;

}
.second-section-know-pic {
  margin-left: 15px;
  height: 160px;
  width: 80px;
}
.objetivos-low-pic {

  margin-left: 15px;
  height: 160px;
  width: 80px;
}
.color-objetivos-page {
  background-color: white;
}
.color-last-page {
  background-color: #212322;
}
.last-page-interior {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  /* height: 600px; */
  background-color: #212322;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.last-page-interior-mobile {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  /* height: 1200px; */
  background-color: #212322;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.objetivos-page-interior {
  padding-left: 5%;
  padding-right: 5%;
  /* margin-top: 5%; */
  padding-top: 5%;
  /* height: 100vh; */
  height: 700px;
  background-color: #f8bd00;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.objetivos-page-interior-mobile {
  padding-left: 5%;
  padding-right: 5%;
  /* margin-top: 5%; */
  padding-top: 5%;
  /* height: 100vh; */
  height: 1200px;
  background-color: #f8bd00;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.desarrollos-page-interior{

  padding-left: 5%;
  padding-right: 5%;
  /* margin-top: 5%; */
  padding-top: 5%;
  /* height: 165vh; */
  height: 1400px;
  background-color: #fceeea ;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.desarrollos-page-interior-mobile-first{

  padding-left: 5%;
  padding-right: 5%;
  /* margin-top: 5%; */
  padding-top: 5%;
  /* height: 165vh; */
  /* height: 1450px; */
  background-color: #fceeea;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.desarrollos-page-interior-mobile{

  /* padding-left: 5%;
  padding-right: 5%; */
  /* margin-top: 5%; */
  padding-top: 5%;
  /* height: 165vh; */
  height: 100%;
  background-color: #fceeea;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.padding-mobile{
  padding-left: 5% !important;
  padding-right: 5% !important;
  /* margin-left: 5%;
  margin-right: 5%; */
}
.desarrollos-page-interior{

  padding-left: 5%;
  padding-right: 5%;
  /* margin-top: 5%; */
  padding-top: 5%;
  /* height: 165vh; */
  /* height: 14450px; */
  height: 100%;
  background-color: #fceeea;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.desarrollos-page-interior-first {

  padding-left: 5%;
  padding-right: 5%;
  /* margin-top: 5%; */
  padding-top: 5%;
  /* height: 165vh; */
  /* height: 1450px; */
  background-color: #fceeea;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.title-text-image-desarrollo-over {
  font-family: 'HelveticaBold';
  font-size: 35pt;
  color: #fceeea;
}
.overlay-terraza {
  font-family: 'GilamSemiBold';
  font-size: 80pt;
  color: #f8bd00;
}
.title-text-image-desarrollo-over-detail {
  font-family: 'Helvetica Light';
  font-size: 35pt;
  color: white;
}
.subtitle-text-image-desarrollo-over {
  font-family: 'Helvetica Light';
  font-size: 16pt;
  color: black;
}
.subtitle-text-bold-image-desarrollo-over {
  font-family: 'Helvetica Light';
  font-size: 16pt;
  font-weight: bold;
  color: black;
}
.objetivos-page-text-color {
  color: black;
}
.second-section-objetivos {
  margin-top: 25px;
  margin-bottom: 5%;
}
.objetivos-section-left-text {
  font-size: 35pt;
  font-family: 'GilamBook';
  line-height: 0.75;
  color: #212322;
  /* margin-bottom: 5%; */
  /* margin-bottom: 5%; */
}
.objetivos-text-main-name {
  font-size: 19pt;
  font-family: 'GilamSemiBold';
  color: #212322;
  /* font-weight: 700; */
}
.objetivos-text-title-name {
  font-size: 19pt;
  font-family: 'GilamSemiBold';
  color: #f8bd00;
  /* font-weight: 700; */
}
.helvetica-objetivos {

  font-size: 14px;
  font-family: 'HelveticaLight';
  color: #f8bd00;
}
.desarrollos-section-left-text {
  font-size: 35pt;
  font-family: 'GilamBook';
  line-height: 0.42;
  color: #212322;
  /* margin-bottom: 5%; */
  /* margin-bottom: 5%; */
}
.helvetica-desarrollos-left {

  font-size: 16pt;
  font-family: 'HelveticaLight';
  /* line-height: 0.16; */
  color: #212322;
}

.helvetica-objetivos-left {

  font-size: 16pt;
  font-family: 'HelveticaLight';
  color: #212322;
}
.title-colaboradores-text {

  font-size: 19pt;
  font-family: 'GilamSemiBold';
  color: #212322;
}
.colaboradores-page-interior {
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  padding-top: 5%;
  /* padding-top: 5%; */
  /* height: 20vw; */
  /* height: 450px; */
  /* height: 100%; */
  background-color: #686e7f;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.colaboradores-page-interior-mobile {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  /* height: 20vw; */
  /* height: 600px; */
  background-color: #686e7f;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
}
.colaboradores-logo-pic {

  margin-left: 15px;
  height: 80px;
  width: 80px;
}
.blog-page-interior {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  /* padding-top: 5%; */
  /* height: 35vw; */
  /* height: 450px; */
  background-color: #22222a;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
  color: #ce7a23
}
.blog-page-interior-mobile {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 15%;
  /* height: 35vw; */
  /* height: 1750px; */
  background-color: #22222a;
  align-items: center;
  /* margin-top: 10rem; */
  text-align: center;
  color: #ce7a23
}
.blog-pic {

  /* margin-left: 15px; */
  /* height: 200px; */
  width: 100%;
}

.blog-pic-mobile {

  /* margin-left: 15px; */
  /* height: 300px; */
  /* height:100%; */
  width: 100%;
}
.blog-main-name {
  font-size: 19pt;
  color: #ce7a23;
  font-family: 'GilamSemiBold';
  text-align: left;
  /* font-weight: 700; */
}
.blog-number-main-name {
  font-size: 39pt;
  color: #ce7a23;
  font-family: 'GilamSemiBold';
  /* font-weight: 700; */
}
.row {
  display: flex;
}

.column {
  flex: 50%;
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
}